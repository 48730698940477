import axios from '../../mixins/axiosInstance.js'
import qs from 'qs'
const state = () => ({
    name: "",
    email: "",
    phone: null,
    comment: "",
})

const getters = {}

const actions = {
    async sendContacMessage({ state, dispatch,commit }) {
        try {
            if (state.name && state.email && state.phone && state.comment && state.phone.length === 10) {
                await axios.instance.post("/sendMessage", qs.stringify({
                    name: state.name,
                    email: state.email,
                    phone: state.phone,
                    message: state.comment,
                }));
                commit('updateInputs',['name',''])
                commit('updateInputs',['email',''])
                commit('updateInputs',['phone',null])
                commit('updateInputs',['comment',''])

                dispatch('Snackbars/setSnackbars', {
                    type: 'success',
                    text: 'Mensaje enviado',
                }, { root: true })
            } else {
                dispatch('Snackbars/setSnackbars', {
                    type: 'warning',
                    text: 'Todos los campos deben de estar llenos',
                }, { root: true })
            }
        } catch (error) {
            if (error) {
                dispatch('Snackbars/setSnackbars', {
                    type: 'error',
                    text: 'El mensaje no se pudo enviar',
                }, { root: true })
            }
        }

    }
}

const mutations = {
    async updateInputs(state, value) {
        state[value[0]] = value[1]
    },
    
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}