const tokenValue = async () => {
  if (localStorage.getItem("token")) {
    return localStorage.getItem("token");
  } else {
    let valor = document.cookie.split(";");
    return valor[1].substr(7, valor[1].length);
  }
};

export { tokenValue };
