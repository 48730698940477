  import axios from '../../mixins/axiosInstance.js'
  import qs from 'qs'
  const state = () => ({
    DialogPrivacy:false,
    SignIn: {
      firstname: "",
      lastname: "",
      phone: null,
      email: "",
      password: "",
      passwordVal: "",
      address: {
        address: "",
        address2: "",
        province: "",
        country: "",
        zip: "",
        city: ""
      }
    },
  })

  const getters = {}

  const actions = {
    async SignInAccount({ state, dispatch }) {
      try {
        if(state.SignIn.email&&state.SignIn.password&&state.SignIn.phone&&state.SignIn.address.address&&state.SignIn.address.address2&&state.SignIn.address.province&&state.SignIn.address.country&&state.SignIn.address.zip&&state.SignIn.address.city&&state.SignIn.firstname&&state.SignIn.lastname){
        if (state.SignIn.password === state.SignIn.passwordVal) {
          await axios.instance.post("/users", qs.stringify({
            email: state.SignIn.email,
            password: state.SignIn.password,
            phone_number: state.SignIn.phone,
            address: state.SignIn.address,
            firstname: state.SignIn.firstname,
            lastname: state.SignIn.lastname
          }));
          dispatch('Snackbars/setSnackbars', {
            type: 'success',
            text: 'Cuenta creada correctamente',
          }, { root: true })
          dispatch('clearInputs')
        }else{
          dispatch('Snackbars/setSnackbars', {
            type: 'warning',
            text: 'Las contaseñas deben ser iguales',
          }, { root: true })
        }
      }else{
        dispatch('Snackbars/setSnackbars', {
          type: 'warning',
          text: 'Todos los campos deben de estar llenos',
        }, { root: true })
      }
      } catch (error) {
        if (error) {
          dispatch('Snackbars/setSnackbars', {
            type: 'error',
            text: error.response.data.error,
          }, { root: true })
        }
      }
      
    },
    async clearInputs({commit}){
      commit('closePrivacyDialog')
      commit('updateSignInFirstName', '')
      commit('updateSignInLastName', '')
      commit('updateSignInPhoneNumber', '')
      commit('updateSignInEmail', '')
      commit('updateSignInPassword', '')
      commit('updateSignInPasswordVal', '')
      commit('updateSignInAddress', '')
      commit('updateSignInAddress2', '')
      commit('updateSignInProvince', '')
      commit('updateSignInCountry', '')
      commit('updateSignInZip', '')
      commit('updateSignInCity', '')
    }
  }

  const mutations = {
    openPrivacyDialog(state){
      state.DialogPrivacy=true
    },
    closePrivacyDialog(state){
      state.DialogPrivacy=false
    },
    updateSignInAddress(state, value){
      state.SignIn.address.address = value
    },
    updateSignInAddress2(state, value){
      state.SignIn.address.address2 = value
    },
    updateSignInProvince(state, value){
      state.SignIn.address.province = value
    },
    updateSignInCountry(state, value){
      state.SignIn.address.country = value
    },
    updateSignInZip(state, value){
      state.SignIn.address.zip = value
    },
    updateSignInCity(state, value){
      state.SignIn.address.city = value
    },
    updateSignInFirstName(state, value) {
      state.SignIn.firstname = value
    },
    updateSignInLastName(state, value) {
      state.SignIn.lastname = value
    },
    updateSignInPhoneNumber(state, value) {
      state.SignIn.phone = value
    },
    updateSignInEmail(state, value) {
      state.SignIn.email = value
    },
    updateSignInPassword(state, value) {
      state.SignIn.password = value
    },
    updateSignInPasswordVal(state, value) {
      state.SignIn.passwordVal = value
    },
  }


  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }