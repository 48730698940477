import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../mixins/axiosInstance.js'
import GraphQL from '../mixins/axiosInstanceGraphQL'
import Snackbars from './modules/Snackbars'
import LogIn from './modules/LogIn'
import SignIn from './modules/SignIn'
import ContactUs from './modules/ContactUs'
import Recovery from './modules/recoveryPassword'
import Active from './modules/activeAccount'
import qs from 'qs'
import {tokenValue} from '../mixins/setHeader'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    e1: 1,
    Groups: [],
    Products: [],
    inputGroup: "",
    Amounts: [],
    LicenceType: 12,
    sumDevices:0,
    typeOfLicence: [
      { text: "1 mes*", value: 1, price: 203 },
      { text: "6 meses", value: 6, price: 1218 },
      { text: "12 meses", value: 12, price: 2436 }
    ],
    ListOfAddress: [],
    AddressToSend: "",
    addNewDialogAddress: false,
    editAddressDialog: false,
    addAddressInput: {
      address1: "",
      address2: "",
      province: "",
      country: "",
      zip: "",
      city: ""
    },
    editAddressInput: {
      address1: "",
      address2: "",
      province: "",
      country: "",
      zip: "",
      city: ""
    },
    editAddressInputIndex: 0,
    privilegeList: [
      { text: 'Disable text', value: 134217728, status: true },
      { text: 'Stun protection', value: 16777216, status: false },
      { text: 'Management group', value: 8388608, status: true },
      { text: 'Dispatcher account', value: 4194304, status: false },
      { text: 'Last group', value: 2097152, status: true },
      { text: 'Recieve S.O.S', value: 65536, status: true },
      { text: 'Only listen', value: 32768, status: false },
      { text: 'Video', value: 16384, status: false },
      { text: 'View location', value: 4096, status: true },
      { text: 'GPS Positioning', value: 2048, status: true },
      { text: 'Platform Audio', value: 1024, status: true },
      { text: 'Stun', value: 512, status: false },
      { text: 'All call', value: 256, status: true },
      { text: 'Display group members', value: 128, status: true },
      { text: 'Monitor', value: 64, status: true },
      { text: 'Call logs', value: 16, status: true },
      { text: 'Do not disturb if private call', value: 8, status: false },
      { text: 'Change group', value: 4, status: true },
      { text: 'Private call', value: 2, status: true },
      { text: 'Contact', value: 1, status: true }
    ],
    provinces: [
      { text: "Aguascalientes", value: "AGS"},
      { text: "Baja California", value: "BC"},
      { text: "Baja California Sur", value: "BCS"},
      { text: "Campeche", value: "CAMP"},
      { text: "Chiapas", value: "CHIS"},
      { text: "Chihuahua", value: "CHIH"},
      { text: "Ciudad de México", value: "DF"},
      { text: "Coahuila", value: "COAH"},
      { text: "Colima	", value: "COL"},
      { text: "Durango", value: "DGO"},
      { text: "Guanajuato	", value: "GTO"},
      { text: "Guerrero	", value: "GRO"},
      { text: "Hidalgo", value: "HGO"},
      { text: "Jalisco", value: "JAL"},
      { text: "México	", value: "MEX"},
      { text: "Michoacán", value: "MICH"},
      { text: "Morelos", value: "MOR"},
      { text: "Nayarit", value: "NAY"},
      { text: "Nuevo León", value: "NL"},
      { text: "Oaxaca", value: "OAX"},
      { text: "Puebla", value: "PUE"},
      { text: "Querétaro", value: "QRO"},
      { text: "Quintana Roo", value: "Q ROO"},
      { text: "San Luis Potosí", value: "SLP"},
      { text: "Sinaloa", value: "SIN"},
      { text: "Sonora", value: "SON"},
      { text: "Tabasco", value: "TAB"},
      { text: "Tamaulipas", value: "TAMPS"},
      { text: "Tlaxcala", value: "TLAX"},
      { text: "Veracruz", value: "VER"},
      { text: "Yucatán", value: "YUC"},
      { text: "Zacatecas", value: "	ZAC"}
    ],
    dialogConfiguration: false,
    currentRadio: 0,
    index1: 0,
    index2: 0,
    index3: 0
  },
  mutations: {

    selectVariant(state, value) {
      state.Products[value.index].chooseLicenceType = { id: value.value.id, model: value.value.model, index: value.value.index }
    },
    removeGroup(state, value) {
      state.Groups.splice(value, 1)
    },
    setNewGroup(state) {
      if (state.inputGroup) {

        state.Groups.push(state.inputGroup)
      }
    },
    updateGroup(state, value) {
      state.inputGroup = value
    },
    updateProducts(state, value) {
      state.Products = value.reduce((arr, el) => {
        arr.push({
          title: el.node.title,
          images: el.node.images.edges,
          id: el.node.id,
          tags: el.node.tags,
          chooseLicenceType: { id: el.node.variants.edges[0].node.id, model: el.node.variants.edges[0].node.title, index: 0 },
          variants: el.node.variants.edges.reduce((arr, el, index) => {
            arr.push({
              value: { id: el.node.id, model: el.node.title, index: index },
              ToBuy: { count: 0, accounts: [] },
              text: el.node.title,
              price: el.node.price.amount,
              quantity: el.node.quantityAvailable,
              currency: el.node.price.currencyCode,
            });
            return arr
          }, [])
        })
        return arr
      }, [])
    },
    changeStep(state, value) {
      switch (value) {
        case 1:
          state.e1 = value
          break;
        case 2:
          state.sumDevices = state.Products.reduce((arr, el) => {
            arr = arr + el.variants.reduce((arr, el) => {
              arr = arr + parseInt(el.ToBuy.count)
              return arr
            }, 0)
            return arr
          }, 0)
          if (state.sumDevices > 0) {
            state.e1 = value
          }
          break;
        case 3:
          if (state.Groups.length > 0) {
            state.e1 = value
          }
          break;
        case 4:
          state.stringConfirm = state.Products.reduce((arr, el) => {
            arr = arr & el.variants.reduce((arr, el) => {
              arr = arr & el.ToBuy.accounts.reduce((arr, el) => {
                if (el.name.length > 0 && el.password.length > 0) {
                  arr = arr & true
                }
                else {
                  arr = arr & false
                }
                return arr
              }, true)
              return arr
            }, true)
            return arr
          }, true)
          if (state.stringConfirm === 1 && state.Groups.length > 0 && state.sumDevices) {
            state.e1 = value
          }
          break;
        case 5:
          if (state.stringConfirm === 1 && state.Groups.length > 0 && state.sumDevices) {
            state.e1 = value
          }
          break;
      }
    },
    changeAmount(state, value) {
      if (value.value && value.value >= 0) {
        if (state.Products[value.index].variants[value.licence.index].ToBuy.accounts.length > value.value) {
          let diff1=state.Products[value.index].variants[value.licence.index].ToBuy.accounts.length - value.value
          state.Products[value.index].variants[value.licence.index].ToBuy.accounts = state.Products[value.index].variants[value.licence.index].ToBuy.accounts.splice(0, value.value)
          state.sumDevices -= diff1
        } else {
          const diff = value.value - state.Products[value.index].variants[value.licence.index].ToBuy.accounts.length
          state.sumDevices+=diff
          for (let index = 0; index < diff; index++) {
            state.Products[value.index].variants[value.licence.index].ToBuy.accounts.push({
              password:"",name: "", conf: state.privilegeList.reduce((acc, el) => {
                if (el.status == true) {
                  acc.push(el.value)
                }
                return acc
              }, [])
            })
          }
        }
        state.Products[value.index].variants[value.licence.index].ToBuy.count = parseInt(value.value)
      }
    },
    setDefaultConfig(state) {

      for (let i = 0; i < state.Amounts.length; i++) {
        for (let j = 0; j < state.Amounts[i].amount; j++) {
          if (!state.Amounts[i].conf[j]) {
            state.Amounts[i].conf[j] = state.privilegeList.reduce((acc, el) => {
              if (el.status == true) {
                acc.push(el.value)
              }
              return acc
            }, [])
          }
        }
      }
    },
    openDialogConfiguration(state, value) {
      state.dialogConfiguration = true
      state.index1 = value.indexP
      state.index2 = value.indexV
      state.index3 = value.indexR

    },
    closeDialogConfiguration(state) {
      state.dialogConfiguration = false
    },
    changeAccountName(state, value) {
      state.Products[value.indexP].variants[value.indexV].ToBuy.accounts[value.indexR].name = value.value
    },
    changeAccountPassword(state, value){
      console.log(value)
      state.Products[value.indexP].variants[value.indexV].ToBuy.accounts[value.indexR].password = value.value
    },
    changeConfiguration(state, value) {
      state.Products[state.index1].variants[state.index2].ToBuy.accounts[state.index3].conf = value
    },
    generateRandomPass(state, value){
      state.Products[value.indexP].variants[value.indexV].ToBuy.accounts[value.indexR].password = Math.random().toString(36).slice(-8);
    },
    changeLicence(state, value) {
      state.LicenceType = value
    },
    setListOfAddress(state, value) {
      state.ListOfAddress = value
    },
    setAddressToSend(state, value) {
      state.AddressToSend = value
    },

    openAddNewAddressDialog(state) {
      state.addNewDialogAddress = true
    },
    closeAddNewAddressDialog(state) {
      state.addNewDialogAddress = false
    },

    openEditAddressDialog(state, value) {
      state.editAddressInput = value.address
      state.editAddressInputIndex = value.index
      state.editAddressDialog = true
    },
    closeEditAddressDialog(state) {
      state.editAddressDialog = false
    },

    removeOneAddress(state, value) {
      state.ListOfAddress.splice(value, 1);
    },

    editOneAddress(state) {
      state.ListOfAddress[state.editAddressInputIndex] = state.editAddressInput
    },

    updateAddressInputToEdit(state,value){
      state.editAddressInput.address=value
    },
    updateAddress2InputToEdit(state,value){
      state.editAddressInput.address2=value
    },
    updateProvinceInputToEdit(state,value){
      state.editAddressInput.province=value
    },
    updateCountryInputToEdit(state,value){
      state.editAddressInput.country=value
    },
    updateZipInputToEdit(state,value){
      state.editAddressInput.zip=value
    },
    updateCityInputToEdit(state,value){
      state.editAddressInput.city=value
    },


    editAddAddressInput(state, value) {
      state.addAddressInput.address = value
    },
    editAddAddress2Input(state, value) {
      state.addAddressInput.address2 = value
    },
    editAddProvinceInput(state, value) {
      state.addAddressInput.province = value
    },
    editAddCountryInput(state, value) {
      state.addAddressInput.country = value
    },
    editAddZipInput(state, value) {
      state.addAddressInput.zip = value
    },
    editAddCityInput(state, value) {
      state.addAddressInput.city = value
    },
    addOneAddress(state) {
      state.ListOfAddress.push(state.addAddressInput)
    },
    setCheckOutID(state, value) {
      state.checkoutID = value
    },
    setCheckOutURL(state, value) {
      state.checkoutURL = value
    }
  },
  actions: {
    async goToPay({ state, dispatch }) {
      await dispatch('updateShopifyCheckout')
      await dispatch('updateShippingAddress')
      let products = state.Products.reduce((arr,el1)=>{
          arr=arr.concat(el1.variants.reduce((arr,el2)=>{
            if(el2.ToBuy.count>0){
              arr.push({
                name:el1.title+"-"+el2.text,
                quantity:el2.ToBuy.count,
                accounts:el2.ToBuy.accounts
              })
            }

            return arr
          },[]))

          return arr
      },[])
      axios.instance.defaults.headers.common["token"]=await tokenValue()
      let data=await axios.instance.post('/orders?id='+state.checkoutID,qs.stringify({
        products:JSON.stringify(products),
        groups:JSON.stringify(state.Groups)
      }))
      console.log(data)
      window.open(state.checkoutURL, "Pagar productos")
    },
    async updateCheckout({ commit, dispatch }, value) {
      commit('changeAmount', value)
      dispatch('updateShopifyCheckout')

    },
    async updateShippingAddress({ state,rootState }) {
      var address = state.AddressToSend
      console.log(address)
      delete address._id
      address.firstname = rootState.LogIn.name
      address.lastname = rootState.LogIn.lastname
      address = JSON.stringify(address)
      address = address.replace(/"address"/ig, 'address1')
      address = address.replace(/"address2"/ig, 'address2')
      address = address.replace(/"city"/ig, 'city')
      address = address.replace(/"country"/ig, 'country')
      address = address.replace(/"province"/ig, 'province')
      address = address.replace(/"zip"/ig, 'zip')
      address = address.replace(/"lastname"/ig, 'lastName')
      address = address.replace(/"firstname"/ig, 'firstName')
      await GraphQL.instance.post("", {
        query: `mutation {
          checkoutShippingAddressUpdateV2(shippingAddress: ${address}, checkoutId:  "${state.checkoutID}") {
            checkout {
              id
              shippingAddress{
                address1
                city
                country
              }
            }
            checkoutUserErrors {
              code
              field
              message
            }
          }
        }`})
    },
    async updateShopifyCheckout({ state }) {
      var lineItems = state.Products.reduce((arr, el) => {

        var item = el.variants.reduce((arr, el) => {
          if (el.ToBuy.count > 0) {
            arr.push({ variantId: el.value.id, quantity: el.ToBuy.count })
          }
          return arr
        }, [])
        arr = arr.concat(item)
        return arr
      }, [])

      lineItems = JSON.stringify(lineItems)
      lineItems = lineItems.replace(/"variantId"/ig, 'variantId')
      lineItems = lineItems.replace(/"quantity"/ig, 'quantity')
      await GraphQL.instance.post("", {
        query: `mutation {
        checkoutLineItemsReplace(lineItems: ${lineItems}, checkoutId: "${state.checkoutID}") {
          checkout {
             id
            webUrl
             lineItems(first:5) {
               edges {
                 node {
                   id
                   title
                   quantity
                 }
               }
             }
          }
        }
      }`})
    },
    async getCheckoutID({ commit,state }) {
      let data = await GraphQL.instance.post("", {
        query: `mutation {
        checkoutCreate(input: {
          lineItems: []
        }) {
          checkout {
             id
             webUrl
             lineItems(first: 5) {
               edges {
                 node {
                   title
                   quantity
                 }
               }
             }
          }
        }
      }
      `});
      commit('setCheckOutID', data.data.data.checkoutCreate.checkout.id)
      await GraphQL.instance.post("", {
        query: `mutation {
          checkoutAttributesUpdateV2(checkoutId: "${state.checkoutID}", input: {note: "${state.checkoutID}"}) {
            checkout {
              id
            }
            checkoutUserErrors {
              code
              field
              message
            }
          }
        }
        `
      })
      commit('setCheckOutURL', data.data.data.checkoutCreate.checkout.webUrl)
    },
    async paymmentMiddleware({ state }, value) {
      let products = [];
      state.Amounts.forEach(element => {
        products.push({
          id_producto: element.id,
          amount: parseInt(element.amount),
          config: { config: state.privilegeList }
        })
      });
      let val = {
        products: products,
        token: value.token,
        address: { addressToSend: state.AddressToSend },
        amount: value.amount,
        description: value.description,
        currency: 'mxn'
      }
      axios.instance.defaults.headers.common["token"]=await tokenValue()
      axios.instance.post("/orders", qs.stringify(val))
    },
    openDialogConfiguration({ commit }, value) {
      commit('openDialogConfiguration', value)
      commit('setDefaultConfig')
    },
    setNewGroup({ commit }) {
      commit('setNewGroup')
      commit('updateGroup', "")
    },
    editGroup({ commit, state }, value) {
      if (!state.inputGroup) {
        commit('removeGroup', value.index)
        commit('updateGroup', value.value)
      }
    },
    async getAddress({ commit }) {
      axios.instance.defaults.headers.common["token"]=await tokenValue()
      let data = await axios.instance.get("/address");
      commit('setListOfAddress', data.data.data[0].address)
      commit('setAddressToSend', data.data.data[0].address[0])
    },
    async getProducts({ commit }) {
      let data = await GraphQL.instance.post("", {
        query: `query getProducts {
          products(first: 3) {
             edges {
                       node {
                         id
                         title
                     tags
                     images(first: 4) { 
                          edges { 
                            node { 
                              originalSrc
                              transformedSrc(maxWidth: 400, maxHeight: 400) 
                              } 
                           } 
                         } 
                         
                         variants(first: 3) {
                           edges {
                             node {
                               price{
                                   amount
                               }
                              quantityAvailable  
                              price {
                                  amount
                                  currencyCode
                              }
                               id
                               sku
                               title
                             }
                           }
                         }
                       }
                     }
          }
        }
          `,
      });
      commit('updateProducts', data.data.data.products.edges)
    },
    async deleteAddress({ state, commit }, value) {
      commit('removeOneAddress', value)
      axios.instance.defaults.headers.common["token"]=await tokenValue()
      await axios.instance.post("/address", qs.stringify(state.ListOfAddress));
    },
    async editAddress({ commit, state, dispatch }) {
      commit('editOneAddress')
      axios.instance.defaults.headers.common["token"]=await tokenValue()
      await axios.instance.post("/address", qs.stringify(state.ListOfAddress));
      dispatch('getAddress')
      commit('closeEditAddressDialog')
    },
    async addAddress({ commit, state, dispatch }) {
      commit('addOneAddress')
      axios.instance.defaults.headers.common["token"]=await tokenValue()
      await axios.instance.post("/address", qs.stringify(state.ListOfAddress));
      dispatch('getAddress')
      commit('closeAddNewAddressDialog')
    }
  },
  modules: {
    Snackbars,
    Active,
    LogIn,
    SignIn,
    ContactUs,
    Recovery
  }
})
