import Vue from 'vue'
import VueRouter from 'vue-router'
import {isValidToken} from '../mixins/isValidToken'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: async () => {
      console.log(await isValidToken())
      if(await isValidToken()===false){
        
        return import('../views/LogAndSignIn.vue')
      }else{
        
        return import('../views/Home.vue')
      }
    }
  },
  {
    path: '/socialLogin/:token',
    name: 'socialLogin',
    component: () => {
      return import('../views/LogInSocial.vue')
    }
  },
  {
    path: '/recovery/:token',
    name: 'Recovery',
    component: () => {
      return import('../views/changePassword.vue')
    }
  },
  {
    path: '/active/:token',
    name: 'Active',
    component: () => {
      return import('../views/startAccount.vue')
    }
  },
  {
    path: '*',
    redirect: '/'
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
