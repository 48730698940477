import axios from 'axios';

function environment(){
    if(process.env.NODE_ENV === 'development'){
        return 'http://localhost:5051/api/v1'
    }
    else if(process.env.NODE_ENV === 'production'){
        return 'https://productioncrmback.radiotrunk.com/api/v1'
    }
}

const instance = axios.create({
    baseURL: environment(),
    timeout: 0,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded'}
});

export default{instance}