import axios from "../../mixins/axiosInstance.js";
import qs from "qs";
import {tokenValue} from '../../mixins/setHeader'

const environment=()=>{
  if(process.env.NODE_ENV === 'development'){
      return 'http://localhost:5051/api/v1'
  }
  else if(process.env.NODE_ENV === 'production'){
      return 'https://productioncrmback.radiotrunk.com/api/v1'
  }
  }
const state = () => ({
  LogIn: {
    email: "",
    password: "",
  },
  email: "",
  name: "",
  lastname:"",
  recoverField: {
    email: "",
  },
});

const getters = {};

const actions = {
  async openSocialDialog({commit},value){
    axios.instance.defaults.headers.common["token"]=await tokenValue()
    await axios.instance.get("/users/"+value+"/signup");
    commit('updateEmail','')
  },
  async getUserInfo({commit}) {
    try {
      axios.instance.defaults.headers.common["token"]=await tokenValue()
      let data = await axios.instance.get("/users/info");
      commit('updateEmail',data.data.data.email)
      commit('updateName',data.data.data.name)
      commit('updateLastName',data.data.data.lastname)
    } catch (error) {
      if(error){
        console.log(error)
      }
    }
  },
  async LogInAccount({ state, dispatch }) {
    try {
      let data = await axios.instance.post(
        "/users/login",
        qs.stringify({
          email: state.LogIn.email,
          password: state.LogIn.password,
        })
      );
      console.log(data)
      localStorage.setItem("token", data.data.token);
      window.location.reload();
    } catch (error) {
      if (error) {
        console.log(error)
        dispatch(
          "Snackbars/setSnackbars",
          {
            type: "error",
            text: error.response.data.error,
          },
          { root: true }
        );
      }
    }
  },
  async LogOut({commit}) {
    commit("updateName", null);
    commit("updateEmail", null);
    localStorage.removeItem("token");
    location.replace(environment()+'/users/logout')
    // localStorage.removeItem("email");
    // localStorage.removeItem("name");
    
  },
  async recoverPassword({ state, dispatch, commit }) {
    try {
      let data = await axios.instance.post(
        "/users/recovery",
        qs.stringify({
          email: state.recoverField.email,
        })
      );
      dispatch(
        "Snackbars/setSnackbars",
        {
          type: "info",
          text: data.data.success,
        },
        { root: true }
      );
      commit("updateRecoverEmail", "");
    } catch (error) {
      if (error) {
        dispatch(
          "Snackbars/setSnackbars",
          {
            type: "error",
            text: error.response.data.error,
          },
          { root: true }
        );
        commit("updateRecoverEmail", "");
      }
    }
  },
};

const mutations = {
  setToken(state,value){
    console.log(state.null)
    localStorage.setItem("token", value);
  },
  updateLogInEmail(state, value) {
    state.LogIn.email = value;
  },
  updateLogInPassword(state, value) {
    state.LogIn.password = value;
  },
  updateName(state, value) {
    state.name = value;
  },
  updateLastName(state, value) {
    state.lastname = value;
  },
  updateEmail(state, value) {
    state.email = value;
  },
  updateRecoverEmail(state, value) {
    state.recoverField.email = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
