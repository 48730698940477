
import axios from './axiosInstance.js';

const isValidToken= async()=> {
    if(localStorage.getItem("token")){
        axios.instance.defaults.headers.common['token'] = localStorage.getItem("token");
    }else{
        let valor = document.cookie.split(";")
        axios.instance.defaults.headers.common['token']=valor[1].substr(7,valor[1].length)
    }
    let x=true
    await axios.instance.get('/testTokenEcommerce').catch((err) => {
        if(err.response.status){
            if(localStorage.token){
                localStorage.removeItem('token');
                window.location.reload();
                
            }
            x=false
        }
    })
    return x
}
export {isValidToken}