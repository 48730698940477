import axios from '../../mixins/axiosInstance.js'
import qs from 'qs'
const state = () => ({
    password: "",
    passwordVal: "",
    token: ""
})

const getters = {}

const actions = {
    async RecoveryPassword({dispatch,state}) {
        try {
            if (state.password == state.passwordVal) {
                axios.instance.defaults.headers.common['token'] = state.token;
                await axios.instance.post("/users/recoveryFinal", qs.stringify({
                    password: state.password,
                }));
                dispatch('Snackbars/setSnackbars', {
                    type: 'success',
                    text: 'Contraseña actualizada',
                }, { root: true })
                this.window.location.href = 'home';
            } else {
                dispatch('Snackbars/setSnackbars', {
                    type: 'warning',
                    text: 'Las contaseñas deben ser iguales',
                }, { root: true })
                
            }
        } catch (error) {
            if (error) {
                console.log (error)
                dispatch('Snackbars/setSnackbars', {
                    type: 'error',
                    text: error.response.data.error,
                }, { root: true })
            }
        }
    }
}

const mutations = {
    updateRecoveryPassword(state, value) {
        state.password = value
    },
    updateRecoveryPasswordVal(state, value) {
        state.passwordVal = value
    },
    setToken(state, value) {
        state.token = value
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}