<template>
  <v-app id="inspire">
      <router-view>
        
      </router-view>
      <Snackbars/>
  </v-app>
</template>

<script>
import Snackbars from './components/snackbars.vue'
export default {
  name: 'App',
  components:{
    Snackbars
  },
  data: () => ({
    //
  }),
};
</script>
