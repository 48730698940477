import axios from 'axios';

const instance = axios.create({
    baseURL: "https://radiotrunk.myshopify.com/api/2021-07/graphql.json",
    headers: { 
        'Content-Type':'application/json',
        
        'X-Shopify-Storefront-Access-Token': '7d3a9df371bef0d7dfc8f229130cc6b7'
    }
});

export default{instance}