import axios from '../../mixins/axiosInstance.js'
const state = () => ({
    token: ""
})

const getters = {}

const actions = {
    async ActiveAccount({dispatch,state}) {
        try {
            await axios.instance.get("/users/active?token="+state.token);
            dispatch('Snackbars/setSnackbars', {
                type: 'success',
                text: 'Usuario Activado',
            }, { root: true })

        } catch (error) {
            if (error) {
                console.log (error)
                dispatch('Snackbars/setSnackbars', {
                    type: 'error',
                    text: 'Algo salio mal',
                }, { root: true })
            }
        }
    }
}

const mutations = {

    setToken(state, value) {
        state.token = value
    }
}


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}